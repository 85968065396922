export default {
  colors: {
    background: '#ffffff',
    text: '#202528',
    secondary: '#C9D0D4',
    tertiary: '#f3f3f3',
    info: { base: '#4787D1', light: '#96C5E7' },
    success: { base: '#7DC24C', light: '#BFE88C' },
    warning: { base: '#F2AD0D', light: '#FFDB80' },
    danger: { base: '#E85B52', light: '#F59681' },
    neutral: { dark: '#919699', base: '#C9D0D4', light: '#F2F5F7' },
    white: '#ffffff',
    black: '#202528',
    gradients: {
      dark: 'to top left, #434343, #202528 60%',
      colorful: 'to right, #f64f59, #c471ed, #12c2e9',
      colorfulInvert: 'to right, #12c2e9, #c471ed, #f64f59',
    },
  },
  fonts: {
    body: 'Inter, Roboto, Helvetica, Arial, sans-serif',
    heading: 'Roboto Slab, serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: {
    xxxxl: '2.875rem', // 46px
    xxxl: '2.375rem', // 38px
    xxl: '2rem', // 32px
    xl: '1.75rem', // 28px
    lg: '1.5rem', // 24px
    md: '1.25rem', // 20px
    base: '16px', // 16px
    sm: '0.875rem', // 14px
    xs: '0.750rem', // 12px
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35rem',
    medium: '50rem',
    large: '70rem',
  },
}
