require("prism-themes/themes/prism-atom-dark.css");
require("prismjs/plugins/line-numbers/prism-line-numbers.css");

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `Wir haben den Blog aktualisiert. ` + `Soll die Seite neu geladen werden?`
  )

  if (answer === true) {
    window.location.reload()
  }
}
